//@ts-nocheck

import React, { useEffect, useState } from "react";
import "grapesjs/dist/css/grapes.min.css";
import "grapesjs/dist/grapes.min.js";
import usePatchHook from "../../customHooks/usePatchHook";
import APIS from "../../contants/EndPoints";
import { useParams } from "react-router-dom";
import useGetHook from "../../customHooks/useGetHook";
import loadable from "@loadable/component";
import useFormDataPostHook from "../../customHooks/useFormDataPostHook";
import { toast } from "react-toastify";
import useDeleteHook from "../../customHooks/useDeleteHook";
import useFormDataPatchHook from "../../customHooks/useFormDataPatchHook";


const PageEditorComponent = loadable(
  () => import("../../components/PageEditor")
);

const PageEditor = (props: any) => {
  const { ID, id } = useParams();
  const [editor, setEditor] = useState(null);

  const { isLoading: isPatchLoading, mutate: patchPageContent } = usePatchHook({
    queryKey: ["pages"],
  });

  const [mediaState, setMediaState] = useState([]);

  const { isLoading: singlePageLoading, data: singlePageData } = useGetHook({
    queryKey: [`page-${ID}`],
    url: `${APIS.PAGES}${ID}/`,
  });
  const { isLoading: mediaLoading, data: media } = useGetHook({
    queryKey: ["page-media"],
    url: APIS.MEDIA,
  });

  const { isLoading: mULoading, mutate: uploadMedia } = useFormDataPostHook({
    queryKey: ["page-media"],
  });

  const { isLoading: dMediaLoading, mutate: deleteMedia } = useDeleteHook({
    queryKey: ["page-media"],
  });

  const handleSaveContent = async (editor) => {
    const projectData = editor.getProjectData();
    const url = `${APIS.PAGES}${ID}/`;
    const formData = {
      id: ID,
      html_content: editor.getHtml(),
      css_content: editor.getCss(),
      json_content: editor.getComponents(),
      project_data: projectData,
    };

    try {
      await patchPageContent({ url, formData });
      editor.clearDirtyCount();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const handleMediaUpload = async (media, editor) => {
    const url = APIS.MEDIA;
    if (!media.length) {
      toast.error("Empty media files");
      return;
    }
    let formData = new FormData();
    formData.append("title", media?.[0]?.name);
    formData.append("description", media?.[0]?.name);
    formData.append("image", media?.[0]);
    try {
      const res = await uploadMedia({ url, formData });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  useEffect(() => {
    if (media) {
      let m = media?.map((item: any, index: number) => ({
        type: "image",
        src: item.image,
        height: 350,
        width: 250,
        name: item?.title ?? "N/A",
        id: item?.id,
      }));
      setMediaState(m);
      editor?.AssetManager.render(m);
    }
  }, [media]);

  const handleDeleteMedia = async (id) => {
    const url = `${APIS.MEDIA}${id}/`;
    try {
      const res = deleteMedia({ url });
      if (res) {
        editor?.AssetManager.render(media);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  let assets = editor?.AssetManager.getAll(); // <- Backbone collection
  assets?.on("remove", function (asset) {
    handleDeleteMedia(asset.get("id"));
  });

  const { mutateAsync: seoMutateAsync } = useFormDataPatchHook({ queryKey: ["pages"] })

  const handleSeoUpdate = async (values: any) => {
    const url = `${APIS.SEO_META_DATA}${singlePageData?.page_seo_meta_id}/`
    let formData = new FormData()
    formData.append("title", values.seo_title)
    formData.append("slug", values.slug)
    formData.append("description", values.description)
    formData.append("keywords", values.keywordPhase?.join(","))
    formData.append("image", values.image)
    console.log(formData)
    try {
      await seoMutateAsync({ url, formData })
    } catch (err) {
      console.log(err);
    }
  }

  const { isLoading: seoDataLoading, data: seoData } = useGetHook({
    queryKey: [`pages-${singlePageData?.page_seo_meta_id}`],
    url: `${APIS.SEO_META_DATA}${singlePageData?.page_seo_meta_id}`,
    params: ""
  });

  return (
    <>
      {seoDataLoading ? (
        "Loading"
      ) : (
        <PageEditorComponent
          handleSaveContent={handleSaveContent}
          ID={ID}
          media={mediaState}
          editor={editor}
          setEditor={setEditor}
          singlePageData={singlePageData}
          handleMediaUpload={handleMediaUpload}
          handleSeoUpdate={handleSeoUpdate}
          seoData={seoData}

        />
      )}
    </>
  );
};

export default PageEditor;
