
import React, { useState } from "react";
import useGetHook from "../../customHooks/useGetHook";
import APIS from "../../contants/EndPoints";
import loadable from "@loadable/component";
import usePostHook from "../../customHooks/usePostHook";

const ThemeComponent = loadable(() => import("../../components/Theme"));


const Theme = () => {
  const [themeId, setThemeId] = useState<string>("")
  //@ts-ignore
  const { data: themes } = useGetHook({
    queryKey: ["themes"],
    url: `${APIS.THEMES}`,
  });


  const { isPostLoading, mutate } = usePostHook({ queryKey: ["change-theme"], navigateURL: "/themes" });

  // CREATE_USER
  const handleActivateTheme = async () => {

    const url = APIS.CHANGE_THEME;
    

    const formData = {
      theme_id: themeId,
    };
    try {
      await mutate({ url, formData })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <ThemeComponent
      themes={themes}
      themeId={themeId}
      setThemeId={setThemeId}
      isLoading={isPostLoading}
      handleActivateTheme={handleActivateTheme}
    />
  );
};

export default Theme;
