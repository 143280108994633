// @ts-nocheck
import useGetHook from "../../customHooks/useGetHook";
import APIS from "../../contants/EndPoints";
import loadable from "@loadable/component";
import usePostHook from "../../customHooks/usePostHook";


const PageComponent = loadable(() => import("../../components/Component/component"));

const Component = () => {
  const { data: themeComponents } =
    useGetHook({
      queryKey: ["themeComponents"],
      url: `${APIS.THEME_COMPONENTS}`,
    });

  // RESET_THEME_CONTENT
  const { mutate } = usePostHook({ queryKey: ["themeComponents"], navigateURL: "/components" });

  //  RESET_THEME_CONTENT
  const handleResetThemeContent = async (values) => {
    const url = APIS. RESET_THEME_CONTENT;

    const formData = values;
    try {
      mutate({ url, formData })
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <PageComponent
      themeComponents={themeComponents}
      handleResetThemeContent={handleResetThemeContent}
    />
  );
};

export default Component;
