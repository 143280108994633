import { useMutation, useQueryClient } from "@tanstack/react-query";
import { patchApiData } from "../helpers/AxiosInstance";
import { toast } from "react-toastify";

const usePatchHook = ({ queryKey }) => {
  const queryClient: any = useQueryClient();

  const { isLoading, mutate, isSuccess, data, isError } = useMutation(
    patchApiData,
    {
      onSuccess: (data) => {
        if (data.status === 200) {

          toast.success("Saved Successfully");
        } else {
          toast.error("Some things went wrongs");
        }
      },
      onError: (error) => {
        toast.error("Some things went wrongs");
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKey ?? null);
      },
    }
  );
  return {
    isLoading,
    mutate,
    isSuccess,
    data,
    isError,
  };
};

export default usePatchHook;
