import React, { useState } from "react";
import { highlight, languages } from "prismjs/components/prism-core";
import Editor from "react-simple-code-editor";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-css.js";
import "prismjs/components/prism-python.js";
import "prismjs/components/prism-markup-templating.min.js";
import "prismjs/components/prism-django.js";
import "./prism-vsc-dark-plus.css";
import "prismjs/plugins/line-numbers/prism-line-numbers";
import "prismjs/plugins/line-numbers/prism-line-numbers.css";
import "./editor.css";

// for styling code editor
// https://github.com/stylelint/stylelint-demo/blob/6988cac4f5bcf01999de2234193e212121ad03ee/src/common/linter/index.js#L51-L57

const CodeEditor = (props: any) => {
  const {code, setCode} = props;
  return (
    <>
      <Editor
        value={code}
        onValueChange={(code) => setCode(code)}
        highlight={(code) => highlight(code, languages.jinja2)}
        padding={10}
        style={{
          fontSize: 14,
        }}
      />
    </>
  );
};

export default CodeEditor;
