import loadable from "@loadable/component";
import APIS from "../../contants/EndPoints";
import useGetHook from "../../customHooks/useGetHook";
import useFormDataPatchHook from "../../customHooks/useFormDataPatchHook";

const SettingComponent = loadable(() => import("../../components/Setting/index"));


const Setting = () => {
  //@ts-ignore
  const { isLoading: loading, data: basicWebsiteInfoData } = useGetHook({
    queryKey: ["basicwebsiteinfo"],
    url: `${APIS.BASIC_WEBSITE_INFO}`,
  });


  const {
    mutateAsync,
  } = useFormDataPatchHook({ queryKey: [`basicwebsiteinfo`] })

  const handleSubmit = async (values) => {
    const url = `${APIS.BASIC_WEBSITE_INFO}${basicWebsiteInfoData?.[0]?.id}/`;


    let formData = new FormData();
    formData.append("favicon_icon", values?.favicon_icon[0] ?? "")
    formData.append("title", values?.title)

    try {
      await mutateAsync({ url, formData });
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div>
      <h3 className="ml-2 font-bold text-2xl text-[#5b6ab0] active">Setting</h3>
      <hr />
      {loading ? "Loading..." :
        <SettingComponent basicWebsiteInfoData={basicWebsiteInfoData} onSubmit={handleSubmit} />
      }
    </div>
  );
};

export default Setting;
